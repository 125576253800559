import { DataTransfer } from 'frontend-core';
import * as errorDispatch from 'redux/ducks/error';
import { AxiosResponse, AxiosError } from 'axios';
import {
  DashboardActions,
  DashboardState,
  DashboardActionTypes,
  ApiDashboardMetric
} from './types';
import { ApiError, ErrorActions } from 'redux/ducks/error';
import { ThunkResult } from 'redux/types';
import { Period, TimeRange } from 'redux/ducks/reportFilter';
import { mapToStatusMetrics } from 'redux/ducks/dashboard/util';

export * from './types';

export const initialState: DashboardState = {
  state: 'init',
  metrics: [],
  vacation: []
};

export default function (state = initialState, action: DashboardActions): DashboardState {
  switch (action.type) {
    case DashboardActionTypes.FETCH_REQUEST: {
      return { ...state, state: 'loading' };
    }

    case DashboardActionTypes.FETCH_SUCCESS: {
      return { ...state, ...action.payload, state: 'idle' };
    }

    case DashboardActionTypes.FETCH_ERROR: {
      return {
        ...state,
        state: 'idle'
      };
    }

    default: {
      return state;
    }
  }
}

const transfer = new DataTransfer();

export interface FetchOptions {
  customerIds: number[] | string[];
  timeRange: TimeRange;
  period: Period;
  dimension?: string;
  includeMetrics?: string[];
}

export const fetchMetrics =
  (
    options: FetchOptions
  ): ThunkResult<Promise<DashboardActions | ErrorActions>, DashboardActions | ErrorActions> =>
  async (dispatch) => {
    const {
      customerIds,
      timeRange: { from, to },
      period,
      dimension,
      includeMetrics
    } = options;

    dispatch({ type: DashboardActionTypes.FETCH_REQUEST });

    const params = {
      from,
      to,
      period,
      accounts: customerIds.join(','),
      dimension
    };

    if (includeMetrics) {
      params['includeMetrics'] = includeMetrics.join(',');
    }

    try {
      const response = (await transfer.get('/foodwaste/dashboard', { params })) as AxiosResponse<{
        metrics: ApiDashboardMetric[];
        vacation: TimeRange[];
      }>;

      return dispatch({
        type: DashboardActionTypes.FETCH_SUCCESS,
        payload: {
          vacation: response.data.vacation ?? [],
          metrics: mapToStatusMetrics(response.data.metrics)
        }
      });
    } catch (err: unknown) {
      const error = err as AxiosError<ApiError>;
      dispatch({ type: DashboardActionTypes.FETCH_ERROR, payload: error });
      return dispatch(errorDispatch.displayError(err as Error));
    }
  };

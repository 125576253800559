import * as React from 'react';
import EmissionDistribution from './EmissionDistribution';
import * as reportDispatch from 'redux/ducks/reportData';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { ReportActions } from 'redux/ducks/reportFilter';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { CHART_COLORS } from 'pages/Report';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import HighestLowestProduct from 'pages/Report/Sustainability/components/EmissionDistribution/HighestLowestProduct';
import { getHighestLowestEmissionByCategory } from 'redux/ducks/reportData/selector';
import { useIntl } from 'react-intl';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type EmissionDistributionContainerProps = DispatchProps & StateProps;

const identifier = 'foodWasteOverview';

const EmissionDistributionContainer: React.FunctionComponent<EmissionDistributionContainerProps> = (
  props
) => {
  const intl = useIntl();
  const { fetchData, foodwasteOverview, highestLowestEmission, filter, state } = props;

  useEffect(() => {
    void fetchData(identifier);
  }, [filter.timeRange, filter.period, filter.filter]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} container>
        <Card>
          <CardHeader
            title={intl.formatMessage({ id: 'report.sustainability.highAndLow.title' })}
          />
          <CardContent>
            <Typography variant={'body1'} paragraph={true}>
              {intl.formatMessage({ id: 'report.sustainability.highAndLow.description' })}
            </Typography>
            <HighestLowestProduct
              highestLowestEmission={highestLowestEmission}
              error={foodwasteOverview.error}
              isLoading={state !== 'idle'}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} container>
        <EmissionDistribution chartsData={foodwasteOverview} chartColors={CHART_COLORS} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.dashboard.state,
  foodwasteOverview: state.reportData[identifier],
  highestLowestEmission: getHighestLowestEmissionByCategory(state),
  filter: state.reportFilter
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: reportDispatch.ReportDataIdentifier) =>
    dispatch(reportDispatch.fetchData(identifier, { basis: 'total' }))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(EmissionDistributionContainer);
